const filters = {
    noVoid: function (value) {
        if (value && value != '-1') {
            return value
        } else {
            return '--'
        }

    },
    noVoidNum: function (value) {
        if (value && value != '-1') {
            return value
        } else {
            return '0'
        }

    },
    timeFormat: function (value) {
        if (value && value != '-1') {
            return value.split(" ")[0]
        } else {
            return '--'
        }

    }
}
export default filters;