/**
 * 登录模块的ajax函数
 */

// post请求示例
/* export const addGoodsCate = (data) => {
    return request({
        url: '/goods/addcate',
        method: "post",
        data
    })
} */

// get请求示例
/* export const getCateList = (params) => {
    return request({
        url: '/goods/catelist',
        method: "get",
        params
    })
} */

// 引入设置好的axios
import request from '@/utils/request'


// 登录
// export const checkLogin = (data) => {
//   return request({
//     url: '/admin/login',
//     method: "post",
//     data
//   })
// }

// // 修改密码
// export const modifyPwd = (data) => {
//   return request({
//     url: '/admin/resetYourSelves',
//     method: "post",
//     data
//   })
// }

// 获取登录二维码
export const getQrcode = (params) => {
  return request({
    url: '/api/login/qrcode',
    method: "get",
    params
  })
} 

// 获取验证码
export const getCodeApi = (params) => {
  return request({
    url: '/api/user/getCode',
    method: "get",
    params
  })
} 

// 获取验证码
export const loginApi = (params) => {
  return request({
    url: '/api/user/phoneLogin',
    method: "get",
    params
  })
} 
