<template>
  <div class="login">
    <div class="title">
      <img
        class="icon_logo"
        src="https://www.zizhihelp.com/upload/logo_round.png"
        alt=""
      />
      <div class="name">资质帮</div>
    </div>

    <div class="panel_wrapper">
      <img src="https://www.zizhihelp.com/upload/static/web/login_left_img.png" class="left_img"></img>

      <div class="panel">
        <div class="qrcode_wrapper" v-if="isQrCode">
          <div class="login_title">二维码登录</div>

          <div class="qrcode_img_wrapper">
            <div class="qrcode_img">
              <img :src="qrCodeUrl" class="img" alt="" />
            </div>

            <div class="tips">微信扫码 安全登录</div>
          </div>
        </div>

        <div class="account_wrapper" v-if="!isQrCode">
          <div class="login_title">
            <div
              :class="['title_item', titleActiveIndex == index ? 'active' : '']"
              v-for="(item, index) in accountTitle"
              @click="changeActiveTitle(index)"
            >
              {{ item }}
            </div>
          </div>

          <el-form :model="loginForm" :rules="rules" ref="loginForm">
            <el-form-item prop="phone">
              <el-input
                placeholder="手机号"
                v-model.trim="loginForm.phone"
                clearable
              >
              </el-input>
            </el-form-item>

            <el-form-item prop="password" v-if="titleActiveIndex == 1">
              <el-input
                @keydown.native.enter="login"
                v-model.trim="loginForm.password"
                placeholder="密码"
                :type="visible ? 'text' : 'password'"
              >
                <i
                  slot="suffix"
                  class="iconfont"
                  :class="visible ? 'icon-eye-open' : 'icon-eye-close'"
                  @click="changeType"
                ></i>
              </el-input>
            </el-form-item>

            <el-form-item prop="code" v-if="titleActiveIndex == 0">
              <div class="code_input">
                <el-input
                  @keydown.native.enter="login"
                  v-model.trim="loginForm.code"
                  placeholder="验证码"
                >
                </el-input>

                <div class="getCode_btn" @click="getCode">
                  {{ isSendCode ? `${countdown}s后重新发送` : "获取验证码" }}
                </div>
              </div>
            </el-form-item>

            <div class="find_pwd" v-if="titleActiveIndex == 1">忘记密码</div>

            <el-checkbox
              v-model="checked"
              :class="titleActiveIndex == 0 ? 'mt69' : ''"
            >
              已阅读并同意
              <span class="agreement" @click="protocolShow = true">
                《服务协议》
              </span>
              <!-- <span class="agreement">《隐私协议》</span> -->
            </el-checkbox>

            <el-form-item>
              <el-button type="primary" @click="login" class="login-btn">
                登录
              </el-button>
            </el-form-item>
          </el-form>
        </div>

        <!-- <div class="type_tag" @click="isQrCode = !isQrCode">
          <img
            :src="`https://www.zizhihelp.com/upload/static/web/${
              !isQrCode ? 'login_qrcode' : 'login_computer'
            }.png`"
            class="type_img"
            alt=""
          />
          <span class="tips">
            {{ !isQrCode ? "微信扫码登录" : "账号登录" }}
          </span>
        </div> -->
      </div>
    </div>

    <div class="icp">
      <span class="mr10">©2024四川迪凯特教育科技有限公司</span>
      <a href="http://beian.miit.gov.cn" target="_blank">蜀ICP备18009707号</a>
    </div>

    <!-- 协议 -->
    <customDialog v-model="protocolShow" title="隐私协议" width="1120px">
      <div class="protocol" v-html="protocol"></div>

      <div style="margin-top: 20px; text-align: center">
        <el-button type="primary" @click="protocolShow = false">
          已阅读并关闭
        </el-button>
      </div>
    </customDialog>
  </div>
</template>

<script>
// 引入ajax函数
import { getQrcode, getCodeApi, loginApi } from "../../api/login";
// 引入工具函数
import local from "@/utils/local";
import protocol from "@/utils/protocol.js";
import md5 from "js-md5";

export default {
  // 数据
  data() {
    return {
      accountTitle: ["验证码登录"],
      titleActiveIndex: 0,
      checked: true,
      // 登录表单数据
      loginForm: {
        phone: "",
        password: "",
      },

      // 验证规则
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },

      // 是否显示密码
      visible: false,

      loginType: "qrcode",
      isQrCode: false,
      qrCodeUrl: "",
      uuid: "",

      isSendCode: false,
      countdown: 60,
      protocolShow: false,
      protocol: protocol,
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取二维码图片
    async getQrcodeImg() {
      const { code, data } = await getQrcode();
      if (code == 0) {
        const { qrCode, uuid } = data;
        this.qrCodeUrl = qrCode;
        this.uuid = uuid;
      }
    },

    /* 操作 */
    // 获取验证码
    async getCode() {
      let valid = false;
      this.$refs.loginForm.validateField("phone", (errMsg) => {
        if (!errMsg) {
          valid = true;
        } else {
          valid = false;
        }
      });
      if (!valid || this.isSendCode) return;

      const { code } = await getCodeApi({
        phone: this.loginForm.phone,
      });
      if (code == 0) {
        this.$message.success("发送成功！");
        this.isSendCode = true;
        setInterval(() => {
          this.countdown--;
          if (this.countdown < 0) {
            this.isSendCode = false;
            this.countdown = 60;
          }
        }, 1000);
      }
    },
    // 切换标题的激活项
    changeActiveTitle(index) {
      this.titleActiveIndex = index;
    },
    // 点击切换密明文
    changeType() {
      this.visible = !this.visible;
    },
    // 登录
    async login() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          const { code, data, msg } = await loginApi(this.loginForm);
          if (code === 0) {
            // 将token存入本地
            // local.set("token", res.data.token);
            // 将用户信息存入本地
            local.set("userInfo", data);
            // 跳转到首页
            this.$router.push("/home");
          } else {
            this.$message.error(msg);
            return;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },

  created() {
    // this.getQrcodeImg();
  },
};
</script>

<style lang="less" scoped>
.login {
  min-width: 1200px;
  min-height: 100%;
  background: url(https://www.zizhihelp.com/upload/static/web/bg_login.png)
    no-repeat 0 0;
  background-size: cover;
  padding: 30px 30px 0px;
  box-sizing: border-box;

  .title {
    display: flex;
    align-items: center;
    color: #333;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: 2px;
    margin-bottom: 20px;
    white-space: nowrap;

    .icon_logo {
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
  }

  .panel_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-width: 1200px;

    .left_img {
      width: 600px;
      min-width: 600px;
      height: 600px;
      min-height: 600px;
      margin-right: 100px;
    }

    .type_tag {
      position: absolute;
      right: 0px;
      top: 0px;
      cursor: pointer;

      .type_img {
        width: 72px;
        height: 72px;
      }

      .tips {
        display: inline-block;
        color: #1472ff;
        font-size: 14px;
        position: absolute;
        right: 72px;
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
        line-height: 28px;
        background: url(https://www.zizhihelp.com/upload/static/web/login_tips_bg.png)
          no-repeat 0 0;
        background-size: 100% 100%;
        padding-left: 10px;
        padding-right: 15px;
      }
    }

    .panel {
      background: #fff;
      padding: 64px 40px 40px;
      border-radius: 8px;
      box-shadow: 0 8px 24px rgba(42, 94, 173, 0.06);
      position: relative;
      min-width: 380px;
      min-height: 462px;

      .qrcode_wrapper {
        .login_title {
          font-size: 20px;
          color: #333;
          text-align: left;
          font-weight: 600;
          margin: 20px 0px;
        }

        .qrcode_img_wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          line-height: 14px;
          font-size: 14px;
          color: #999;

          .qrcode_img {
            background: url(https://www.zizhihelp.com/upload/static/web/login_border.png)
              no-repeat 0 0;
            background-size: 100% 100%;
            width: 200px;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 40px 50px;
            padding: 10px;

            .img {
              width: 100%;
              height: 100%;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .account_wrapper {
        .login_title {
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          padding-right: 160px;

          .title_item {
            margin-right: 40px;
            cursor: pointer;
            font-weight: 700;
            color: #666;
            font-size: 20px;
            text-align: left;
          }

          .active {
            color: #333;
            position: relative;
            -webkit-transition: all 0.2s linear;
            transition: all 0.2s linear;

            &::after {
              display: inline-block;
              content: "";
              width: 100%;
              height: 3px;
              border-radius: 7px;
              background-color: #1472ff;
              position: absolute;
              bottom: -8px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }

        .el-form-item {
          margin-top: 20px;
        }

        .el-button {
          width: 100%;
        }

        .el-button--primary {
          background-color: #1472ff !important;
          border-color: #1472ff !important;
          border-radius: 4px !important;
        }

        /deep/.el-input-group__append {
          background-color: #fff !important;
          border-left: none !important;
          color: #1472ff;
          cursor: pointer;
          padding: 0 18px;
        }

        .code_input {
          position: relative;

          .getCode_btn {
            color: #1472ff;
            cursor: pointer;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }

          /deep/.el-input__inner {
            padding-right: 90px;
          }
        }

        .find_pwd {
          font-size: 14px;
          color: #333;
          cursor: pointer;
          text-align: right;
          margin: 30px 0px 45px;

          &:hover {
            color: #1472ff;
          }
        }

        .agreement {
          color: #333;
        }
        /deep/.el-checkbox__label,
        /deep/.el-checkbox__input.is-checked + .el-checkbox__label {
          color: #999 !important;
        }

        .mt69 {
          margin-top: 69px;
        }
      }
    }
  }

  .icp {
    text-align: center;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    color: #333;
    font-size: 14px;
    white-space: nowrap;

    a {
      color: #333;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>

<style lang="less">
.protocol {
  height: 500px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #e1e2e5;
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ffffff;
  }
}
</style>
