<template>
  <div class="footer">
    <div class="container">
      <div class="main_content">
        <div class="about wrapper br pr100">
          <div class="title">关于我们</div>
          <div class="list">
            <div class="item">关于我们</div>
            <div class="item">会员服务</div>
            <div class="item">帮助中心</div>
            <div class="item">招贤纳士</div>
          </div>
        </div>

        <div class="contact wrapper br pd100">
          <div class="title">联系我们</div>
          <div class="list">
            <div class="item">客服电话：028-67879999</div>
            <div class="item">合 作：028-67879999</div>
            <div class="item">邮 箱：8558424@qq.com</div>
            <div class="item">公司总部：成都市高新区天府大道北段170</div>
          </div>
        </div>

        <div class="follow wrapper pl100">
          <div class="title">关注我们</div>

          <div class="content">
            <div class="item">
              <div class="code"></div>
              <div class="text">微信公众号</div>
            </div>

            <div class="item">
              <img
                class="code"
                src="https://www.zizhihelp.com/upload/static/web/mpCode.png"
                alt=""
              />
              <div class="text">微信小程序</div>
            </div>
          </div>
        </div>
      </div>

      <div class="icp">
        <span class="mr10">©2024四川迪凯特教育科技有限公司</span>
        <a href="http://beian.miit.gov.cn" target="_blank">蜀ICP备18009707号</a>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/index.js"; // 系统配置

export default {
  // 数据
  data() {
    return {
      baseUrl: config.baseUrl,
    };
  },

  // 生命周期 - 实例创建完成
  created() {},

  //方法
  methods: {},

  // 监听器
  watch: {
    // 监听路由信息对象中的path
    "$route.path"() {
      // this.calcBreaks(); // 当path发生变化的时候执行关联操作
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  background-color: #2b2d42;
  color: #fff;
  font-size: 14px;
  padding: 30px 0px;

  .container {
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .wrapper {
    }
    .br {
      border-right: 1px solid #fff;
    }

    .pd100 {
      padding: 0px 100px;
    }

    .pr100 {
      padding-right: 100px;
    }

    .pl100 {
      padding-left: 100px;
    }

    .title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 26px;
    }

    .list {
      .item {
        margin-bottom: 16px;
      }
    }

    .main_content {
      display: flex;
      align-items: center;
    }

    .follow {
      .content {
        display: flex;
        align-items: center;

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 120px;
          .code {
            width: 100px;
            height: 100px;
            background-color: #fff;
            margin-bottom: 10px;
          }
        }
        :last-child {
          margin-right: 0px;
        }
      }
    }

    .icp {
      text-align: center;
      margin-top: 20px;

      a {
        color: #fff;
      }
    }
  }
}
</style>
