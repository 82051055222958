<template>
  <div v-if="show">
    <el-dialog
      ref="compontent_dialog"
      class="custom_dialog"
      v-dialogDrag
      :fullscreen="fullscreen"
      :visible.sync="show"
      :width="width == '100%' ? '1260px' : width"
      :before-close="handleDialogClose"
      :close-on-click-modal="closeModal"
      :append-to-body="true"
      :top="'13vh'"
    >
      <div slot="title" class="header-title">
        {{ title }}
        <img
          title="全屏"
          @click="fullscreen = !fullscreen"
          class="title_icon"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABkklEQVQ4T6WTPy9DURjGn/f2XENjkBCDgcHHsNn8aXvvSVsJNgYDEt/BZjGYCBMG1fe0pQldhPgONolILAhDOzT3nnPkSnFTbUJ6ppM3eX7vv+cl9PioRz2+AVLKRwCDLeAlM/txuJSyDCDdir0y81D0jwMsgBDASrPZ5Gq1+hYHpFKppOu6iwB2ALjM/KltB6xbazeJyGPmqzggm83Oaq2PAGwQ0f4vgO/7N41GYyqZTM4Q0V4c4vv+LIAjIpoPguDKdd0TZo5iPxXEs2UymTnHcXbDMBwnor5EInEHYEEpdd4+9K5bkFKOMfNDJEin0yOVSuWp08a6AjzPGyiVSu+RKJfL9RcKhfqfAVHPRHRsjBnVWgshxL0xJlculy+7tiClPA+CIC+EmIzERLRQLBbPIoHneXnHcfaNMXmt9bUQ4lQpNf1rjdbaJSLajou/MrYgB9baNSI66OgDa21IRKv1ev2wVqs14uVGc9BaLwPYAiA6AV6+rGytvVBKTbVZ+RbARCv2zMzDXX3wnwPr+Ro/AEdDqhEDnJZfAAAAAElFTkSuQmCC"
        />
      </div>
      <slot> </slot>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "custom_dialog",
  model: {
    prop: "show",
    event: "change",
  },
  props: {
    title: String,
    width: String,
    show: {
      //因为在model中使用了prop: 'checked'，所以这里必须显示的声明这个变量
      type: Boolean,
      default: false,
    },
    closeModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fullscreen: false,
    };
  },
  mounted() {},
  methods: {
    handleDialogClose() {
      this.$emit("change", false);
    },
  },
};
</script>
<style lang="less" scoped>
.el-dialog {
  display: flex;
  display: -ms-flex; /* 兼容IE */
  flex-direction: column;
  -ms-flex-direction: column; /* 兼容IE */
}
.el-dialog .el-dialog__body {
  max-height: 100%;
  flex: 1;
  -ms-flex: 1 1 auto; /* 兼容IE */
  overflow-y: auto;
  overflow-x: hidden;
}
.custom_dialog {
  .el-dialog__header {
    padding: 10px 20px 10px;
  }
  .el-dialog__headerbtn {
    top: 8px;
  }
  .el-dialog__title {
    height: 18px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    line-height: 18px;
  }
  .el-dialog__header {
    background-color: #f8f8f8;
    border-bottom: 1px solid #eee;
  }
  .el-dialog__close {
    color: rgba(0, 0, 0, 1);
  }
  .header-title {
    height: 18px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
    line-height: 18px;
    .title_icon {
      color: #909399;
      position: absolute;
      top: 20px;
      right: 45px;
      padding: 0;
      border: none;
      outline: 0;
      cursor: pointer;
      font-size: 12px;
      line-height: 12px;
    }
  }
  /* 滚动条的样式调整 */
  .scrollbar,
  .el-table__body-wrapper,
  .is-scrolling-none {
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #e1e2e5;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #ffffff;
    }
  }

  /* el-table表格右侧滚动条上方的矩形背景色 */
  .el-table__fixed-right-patch {
    background-color: #f6f7f8 !important;
  }
}
</style>
