/**
 * 本地存储工具
 */

const local = {
    //  获取
    get(key) {
        return JSON.parse(localStorage.getItem(key))
    },
    // 设置
    set(key, val) {
        localStorage.setItem(key, JSON.stringify(val))
    },
    // 移除
    remove(key) {
        localStorage.removeItem(key)
    },
    // 清空
    clear() {
        localStorage.clear()
    }
}

export default local