/* 隐私协议 */
const protocol = `
<b>声明</b><br/>
本指引将帮助您了解以下内容：<br/>
一、我们如何收集和使用您的个人信息<br/>
二、我们如何委托处理、共享、转让、公开披露您的个人信息<br/>
三、我们如何存储和保护您的个人信息<br/>
四、您如何行使您的个人信息权利<br/>
五、我们如何保护未成年人的个人信息<br/>
六、本指引如何更新<br/>
七、如何联系我们<br/><br/><br/>
一、我们如何收集和使用您的个人信息<br/>
1.1【账号注册/登录】<br/>
（1）当您在创建小象建桥账号时，您须提供手机号码。您提交的手机号码用于您注册、登录、绑定账号、密码找回时接受验证码，并且作为您与我们指定的联系方式之一，用来接受相关营销及业务通知（包括电话营销、商业性信息、新品上线、服务变更），并且我们可通过此联系方式向您发送业务通知（含账单）或与您进行业务沟通（“安全手机号码”或“账号联系人手机号码”）。<br/>
收集手机号码是为了满足相关法律法规的网络实名制要求，若您不提供这类信息，您可能无法正常使用我们的核心业务服务。<br/>
（2）您可使用微信账号登录我们的店铺，如您使用微信账号登录的，我们将根据您的授权获取微信账号下的相关信息（包括：昵称、头像及其他页面提示的信息）。我们承诺，收集上述信息是用于为您提供账号登录服务。如您拒绝授权此类信息，则您将无法使用微信账号登录我们的店铺。<br/>
1.2【客服及争议处理】<br/>
当您向我们发起投诉、举报或进行咨询时，为了保障您的账号及系统安全，我们可能需要您提供必要的个人信息（包括账号信息、订单信息）以核验您的身份。<br/>
为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的沟通、通信/通话记录及相关内容（包括账号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的电话号码、电子邮件地址信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息和订单信息。<br/>
1.3【商品或服务交易】<br/>
当您通过我们的店铺订购具体商品及/或服务时，您需要提供与完成交易相关联的信息，包括：<br/>
（1）交易的商品或服务信息、收货人姓名、收货地址、收货人联系电话（敏感个人信息）。<br/>
（2）交易金额、下单时间、订单编号、订单状态、支付方式、支付流水号、支付状态。<br/>
我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、便于您查询订单信息、提供客服与售后服务及其他我们明确告知的目的。<br/>
当您成功下单后，如商品需要第三方配送的，我们为了顺利、安全、准确向您完成货物的交付及配送，第三方配送公司在发货及配送环节内不可避免地会获知您的相关配送信息（敏感个人信息）。您知晓并同意相应物流配送主体不可避免地获知及使用您的配送信息，用于完成交付目的。<br/>
1.4【学习信息】<br/>
当您使用我们的网站查询相关信息，与客户沟通过程中，我们会记录您的使用信息，包括：搜索行为数据、使用时长、聊天记录。我们收集这些数据是为了分析您的使用情况。<br/><br/>

二、我们如何委托处理、共享、转让、公开披露您的个人信息<br/>
2.1委托处理<br/>
我们委托腾讯计算机系统有限公司处理您的及时沟通需求。<br/>
2.2共享<br/>
我们不会与我们关联公司以外的任何公司、组织和个人分享您的个人信息，除非获得您的授权同意。<br/>
2.3转让<br/>
除非获取您的明确同意，我们不会将您的个人信息转让给任何公司、组织或个人。<br/>
2.4公开披露<br/>
我们仅会在以下情况下，公开披露您的个人信息：<br/>
（1）获得您明确同意后；<br/>
（2）基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。<br/>
2.5共享、转让、公开披露您的个人信息的的例外<br/>
以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：<br/>
（1）与国家安全、国防安全直接相关的；<br/>
（2）与公共安全、公共卫生、重大公共利益直接相关的；<br/>
（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；<br/>
（4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br/>
（5）个人信息主体自行向社会公众公开的个人信息；<br/>
（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br/>
（7）根据法律规定，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。<br/><br/>

三、我们如何存储和保护您的个人信息<br/>
3.1个人信息存储<br/>
除法律法规另有规定外，我们对你的信息的保存期限应当为实现处理目的所必要的最短时间。<br/>
3.2个人信息保护<br/>
（1）您的个人信息安全对于我们至关重要。我们将严格遵守相关法律法规，采取业内认可的合理可行的措施，保护您的个人信息。防止信息遭到未经授权的访问、披露、使用、修改，避免信息损坏或丢失。<br/>
（2）一旦发生个人信息安全事件，我们将按照法律法规的要求，及时向你告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、你可自主防范和降低风险的建议、对你的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知你，当难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。<br/><br/>

四、您如何行使您的个人信息权利<br/>
4.1您有权查询、更正或补充您的信息。您可以通过以下方式自行进行：<br/>
登录小象建桥小程序，进入“我的”，点击“头像”后查询或补充个人资料相关信息；<br/>
如果您无法通过上述方式更正信息的，可联系我们处理，我们将在15个工作日内反馈结果，并后台将同步更正相关信息。<br/>
4.2您有权删除您的个人信息：<br/>
在以下情形中，您可通过第七条的联系方式向我们提出删除个人信息的请求：<br/>
（1）如果我们处理个人信息的行为违反法律法规；<br/>
（2）如果我们收集、使用您的个人信息，却未征得您的同意；<br/>
（3）如果我们处理个人信息的行为违反了与您的约定；<br/>
（4）如果您不再使用我们的产品或服务，或您注销了账号；<br/>
（5）如果我们不再为您提供产品或服务。<br/>
收到您的删除请求后，我们会根据您的要求进行删除处理并在15个工作日内向您反馈结果（法律法规要求必须保留的数据除外）。<br/>
当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应信息，但会在备份更新时进行删除。<br/>
4.3您有权改变您授权同意的范围：<br/>
每个业务功能需要一些基本的个人信息才能得以完成，对于额外收集的个人信息的收集和使用，您可以给予或收回您的授权同意。<br/>
如果您希望变更您的授权范围，可通过本指引第七条联系我们的客服处理。<br/>
4.4您有权通过如下方式注销账号：<br/>
登录小象建桥，进入“我的”，点击“账号设置”，进行账号注销。<br/>
4.5个人信息主体获取个人信息副本<br/>
您可以通过本指引第七条联系我们申请获取您的个人信息副本，客服在完成对您的身份核验后，将向您提供个人信息副本。<br/>
4.6撤回您的授权<br/>
您可以通过APP端“我的-关于我们-个人信息保护政策”最下方取消“勾选”来撤回您的授权。当您收回同意后，我们将不再处理相应的个人信息。但撤回授权同意不影响撤回前基于授权同意的个人信息处理。<br/>
4.7响应您的上述请求<br/>
为保障安全，您通过联系客服的方式提出上述请求时需要提供书面文件，或以其他方式证明您的身份。我们将要求您验证自己的身份后响应您的请求。<br/>
我们对于您的书面请求响应时间为15个工作日。<br/>
对于您合理的请求，我们可免费提供服务，如涉及第三方费用，在我们为您提供相应证据后由您自行承担相关成本。请注意，针对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。<br/>
4.8在以下情形中，我们将无法响应您的请求：<br/>
（1）与个人信息控制者履行法律法规规定的义务相关的；<br/>
（2）与国家安全、国防安全直接相关的；<br/>
（3）与公共安全、公共卫生所所生、重大公共利益直接相关的；<br/>
（4）与刑事侦查、起诉、审判和执行判决等直接相关的；<br/>
（5）个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；<br/>
（6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br/>
（7）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；<br/>
（8）涉及商业秘密的。<br/><br/>

五、我们如何保护未成年人的个人信息<br/>
我们非常重视对未成年人个人信息的保护，根据相关法律法规的规定，若您是14周岁以下的未成年人，在您使用我们服务前，应取得您的家长或法定监护人对本隐私保护指引的书面同意。若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过第七条的联系方式与我们联系。<br/><br/>

六、本指引如何更新<br/>
未经您明确同意，我们不会削减您按照本指引所应享有的权利。如本指引更新，我们将在本指引生效前通过在显著位置提示或向您发送推送消息或以其他方式通知您，若您继续使用我们的服务，即表示您充分阅读、理解并同意受经修订的《隐私保护指引》的约束。<br/>
重大变更包括但不限于：<br/>
（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br/>
（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；<br/>
（3）个人信息共享、转让或公开披露的主要对象发生变化；<br/>
（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；<br/>
（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；<br/>
（6）个人信息安全影响评估报告表明存在高风险时。<br/><br/>

七、如何联系我们<br/>
若你认为我们未遵守上述约定，或有其他的投诉建议、或未成年人个人信息保护相关问题，可通过以下方式与我们联系；或者向小象建桥进行投诉。<br/>
`

export default protocol